<template>
  <div class="Done">
    <Header />

    <div class="Section">
      <span class="Title">{{ mixWB('THANKS_FOR_NOW') }}</span>
      <p>{{ mixWB('DONE_LINE_1') }}</p>
      <p>{{ mixWB('DONE_LINE_2') }}</p>
      <p>{{ mixWB('DONE_LINE_3') }}</p>
    </div>

    <transition
      name="fade"
      mode="out-in">
      <div
        v-if="!feedbackSent"
        class="Section">
        <span class="Title">{{ mixWB('GIVE_US_FEEDBACK') }}</span>
        <p><span v-html="mixWB('FEEDBACK_INTRO_TEXT_1', [contactInfo.phone])"/></p>

        <form
          class="FeedbackForm"
          @submit="onFeedbackClick">
          <textarea
            v-model="feedbackText"
            :placeholder="mixWB('FEEDBACK_PLACEHOLDER')"
            @input="onFeedbackInput"/>
          <span
            class="ErrorText"
            v-if="errorText">{{ errorText }}</span>

          <Button
            :text="mixWB('SEND')"
            :isLoading="isLoading"
            @button-click="onFeedbackClick" />
        </form>
      </div>
      <div
        class="Section"
        v-else>
        <span class="Title">{{ mixWB('GIVE_US_FEEDBACK') }}</span>
        <p>Tak for din feedback!</p>
      </div>
    </transition>

    <div class="Section">
      <div class="IconWrap">
        <InlineSVG :src="require('@/assets/svg/colored/go-green-1.svg')" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Questionnaire/Header.vue'
import Button from '@/components/Buttons/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Done',
  data() {
    return {
      feedbackText: '',
      errorText: '',
      isLoading: false,
      feedbackSent: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserData',
      'contactInfo',
    ]),
  },
  methods: {
    onFeedbackInput() {
      this.errorText = ''
    },
    onFeedbackClick(e) {
      if (e) {
        e.preventDefault()
      }
      this.feedbackText = this.feedbackText.trim()
      if (!this.feedbackText) {
        this.errorText = this.mixWB('YOU_NEED_TO_WRITE_SOMETHING')
        return
      }

      this.isLoading = true
      this.$store.dispatch('sendFeedback', this.feedbackText)
      setTimeout(() => {
        this.feedbackSent = true
      }, 500)
    },
  },
  components: {
    Header,
    Button,
  },
  created() {
    if (!this.currentUserData.hasCompletedOnce) {
      this.$store.dispatch('setStatsOnCompletedSurvey')
    }
  },
}
</script>

<style lang="stylus" scoped>
  .Done
    display block

  .Section
    width 600px
    margin 0 auto 20px
    &:last-child
      padding-bottom 200px
      margin-bottom 0
    p,
    span
      text-align center
    p
      padding-bottom 20px
    .Title
      font-size 1.5rem
      font-weight bold
      text-align center
      margin-bottom 15px
    textarea
      width 100%
      min-width 100%
      max-width 100%
      height 150px
      min-height 150px
      max-height 300px
      margin-bottom 10px
      padding 10px 10px 8px
      border 1px solid $color_grey_lighter
      border-radius 0px
      background-color rgba(255, 255, 255, 0.75)

  .FeedbackForm
    max-width 500px
    margin 0px auto
    .ErrorText
      display block
      margin-bottom 10px
      color $color_error
      font-size 0.875rem

  .IconWrap
    box(200px)
    opacity 0.5
    margin 100px auto 0

  .fade-enter-active, .fade-leave-active {
    transition opacity $menu_transition_time = 0.15s ease-in-out
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
